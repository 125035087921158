import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import { graphql } from 'gatsby'
import Container from '../components/container'
import { Heading } from '../components/typography/headings'
import ContentfulPage from '../components/contentfulPage'
import HeroSections from '../components/sections/heroSections'
import * as Sentry from '@sentry/browser'
import RelatedPosts from '../components/cards/relatedPosts'
import RatingsBar from '../components/ratingsBar/ratingsBar'
import ContactUsBar from '../components/contactUsBar/contactUsBar'
import CustomerExperienceSection from '../components/customerExperienceSection/customerExperienceSection'

if (process.env.SENTRY_ENVIRONEMNT !== 'DEVELOPMENT') {
  Sentry.init({
    dsn: 'https://a4c3f522b4f843e7bcbb5f77447ccd75@sentry.io/1835119',
    environment: process.env.SENTRY_ENVIRONEMNT,

    /* related to iframe-resizer, see
     https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/#workaround-3-sentry-error-monitoring */
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  })
}

const propTypes = {
  data: shape({
    page: shape({
      cards: arrayOf(shape({})),
    }).isRequired,
    articles: shape({
      edges: arrayOf(
        shape({
          node: shape({}).isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: shape({
    callToAction: string,
  }).isRequired,
}

const LandingTemplate = ({
  data: {
    page,
    articles: { edges: latestArticles },
    latestPodcast: { edges: latestPodcast },
  },
  pageContext: { callToAction },
}) => {
  return (
    <ContentfulPage page={page}>
      <RatingsBar />
      <HeroSections sections={page.cards} />
      <CustomerExperienceSection />
      <ContactUsBar showDetails={false} />
      <Container isFlowing>
        <RelatedPosts
          latestArticles={latestArticles.map(({ node }) => node)}
          latestPodcast={latestPodcast.map(({ node }) => node)}
          headerContent={
            <Heading as="h2" headingSize="3">
              Artikkelit ja podcastit
            </Heading>
          }
        />
      </Container>
    </ContentfulPage>
  )
}

LandingTemplate.propTypes = propTypes

export default LandingTemplate

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...contentfulPageInformation
    }
    articles: allContentfulArticle(
      limit: 3
      sort: { fields: [publicationDate], order: DESC }
      filter: {
        customerGroups: { eq: null }
        slug: { ne: "laajemmat-tiedot-yrityslainoista" }
      }
    ) {
      edges {
        node {
          __typename
          id
          previewHeading
          slug
          previewHeading
          description {
            description
          }
          category
          publicationDate
          contentType
          image {
            title
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
          previewHeading
          previewBody {
            previewBody
          }
        }
      }
    }
    latestPodcast: allContentfulPodcastEpisode(
      limit: 1
      filter: { isPublished: { eq: true } }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          __typename
          slug
          title
          description {
            description
          }
          category
          episodeNumber
          seasonNumber
          runTime
          isPublished
          contentType
          heading
          body {
            childMarkdownRemark {
              html
            }
          }
          publicationDate
          image {
            title
            gatsbyImageData(width: 800, placeholder: BLURRED)
          }
          previewBody {
            previewBody
          }
          previewHeading
          previewHeroHeading
        }
      }
    }
  }
`
